<template>
  <div class="content">
    <div class="title text-center">現在メンテナンス中です。</div>
    <div class="text-body">
      日頃よりYELLoopを利用いただき、試にありがとうございます。 現在メンテナンスのため、こちらのページはご利用いただけません。
      ご不便をおかけいたしますが、ご現解いただきますようお願い申し上げます。
    </div>
    <div class="text-bottom">
      メンテナンス期間 <br />
      2022年1月1日 0:00~9:00 <br />
      ※メンテナンスは期間延長される可能性をあります。
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content {
  min-height: calc(100vh - 120px);
}
.text-body {
  width: 550px;
  margin: auto;
  padding-bottom: 30px;
}
.text-bottom {
  width: 550px;
  margin: auto;
}
.content .title {
  padding-top: 150px;
  font-size: 25px;
  color: #00b140;
  font-weight: 500;
  padding-bottom: 50px;
}
@media only screen and (max-width: 1023.98px) {
  .content {
    font-size: 14px;
    min-height: calc(100vh - 120px);
  }
  section .title {
    font-size: 20px;
  }
  .text-body {
    width: 350px;
  }
  .text-bottom {
    width: 350px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 350px) {
  .content {
    font-size: 14px;
    min-height: calc(100vh - 120px);
  }
  .content .title {
    font-size: 20px;
    margin: auto;
    width: 90%;
  }
  .text-body {
    width: 90%;
  }
  .text-bottom {
    width: 90%;
    padding-bottom: 30px;
  }
}
</style>
